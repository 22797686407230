
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        








































































































































































































































h2 {
  margin-top: 0;
}
.game {
  display: flex;
  height: 100%;
}
.players {
  border-right: 1px solid black;
  margin-left: -40px;

  h3 {
    margin-top: 0;
    padding-left: 20px;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 10px 20px;

    &.selected {
      background-color: #ccc;
    }
    .badge {
      font-size: 14px;
      text-align: center;
      border: 1px solid black;
      border-radius: 50%;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      margin-left: 10px;
    }
  }
}
.details {
  flex-grow: 1;
  border-left: 1px solid black;
  border-right: 1px solid black;
  margin-left: -1px;
  padding-left: 20px;
  padding-right: 20px;

  .buttons {
    display: flex;
    margin-top: 30px;
  }
  button {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    display: block;

    &.add {
      margin-left: auto;
    }
  }
}
.player {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .avatar {
    border-radius: 50%;
    margin-right: 20px;
    overflow: hidden;
    width: 50px;
    height: 50px;

    img {
      display: block;
      width: 100%;
    }
  }
}
.deck {
  border-left: 1px solid black;
  margin-left: -1px;
  padding-left: 20px;
  max-width: 40%;

  h3 {
    margin-top: 0;
  }
}
